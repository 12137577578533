/*!

=========================================================
* Argon Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import $ from "jquery"

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col
} from "reactstrap";
// core components
import DemoNavbar from "components/Navbars/DemoNavbar.js";
// import SimpleFooter from "components/Footers/SimpleFooter.js";

class Register extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      is_new_user: true,
      unregistered_user: false,
      registered_looksee_user: false,
      loading: false,
      mobile_no: "",
      device: "",
      user_device: "",
      iphone: 0,
      ipad: 0,
      computer: 0,
      username: "",
      password: "",
      promo_iphone: 0,
      promo_ipad: 0,
      promo_computer: 0
    }
    this.messagesEndRef = React.createRef()
  }
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;

    //https://server.applooksee.com/api/v1/promo-count

    fetch('https://server.applooksee.com/api/v1/promo-count', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      },
    }).then(res => res.json())
    .then(data => {
      console.log(data)
      const {iphone, ipad, computer} = data.entity;
      const {iphone: promo_iphone, ipad: promo_ipad, computer: promo_computer} = data.looksee_promo;
      this.setState({
        iphone,
        ipad,
        computer,
        promo_iphone: promo_iphone * 5,
        promo_ipad: promo_ipad * 5,
        promo_computer: promo_computer * 5
      });
    }).catch(err=> {
      console.log(err);
    })
  }

  handleInputChange = (e) => {
    // const value = e.target.type === "checkbox" ? e.target.value : !this.state.active;
    this.setState({
      [e.target.name] : e.target.value
    })
  }
  
  hideURLbar = () => {
    window.scrollTo(0, 0);
  }
  
  handleSubmit = (e) =>  {
    e.preventDefault();
    $(".loading").text(` Loading ...`).css('color', 'red')
    const data = this.state;
    const {mobile_no, device} = data;
    console.log({mobile_no, device});
   

    // fetch('http://localhost:4000/api/v1/looksee-promo', {
    fetch('https://server.applooksee.com/api/v1/looksee-promo', {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({mobile_no, device})
    }).then(res => res.json())
    .then(data => {
      // window.scrollTo({
      //   top: this.statsRef.current.offsetTop,
      //   behavior: 'smooth'     
      // });
      $(".loading").text(``);
      
      if(data.code === 200) {
        const {
          status,
          code,
          message,
  
          entity: {
            is_new_user,
            has_contact_looksee,
            is_left_promo_account,
            username,
            password,
            device,
            user_device
          }
        } = data;

        this.setState({
          loading: true,
          registered_looksee_user: false,
          unregistered_user: false,
          is_new_user,
          username,
          password,
          device: "",
          user_device
        });
        setTimeout(function(){ 
          window.scrollTo({ 
            top: document.documentElement.scrollHeight, 
            behavior: 'auto'
            /* you can also use 'auto' behaviour 
               in place of 'smooth' */
          }); 
         }, 300);
      } else {
        let {
          status,
          code,
          message,
  
          entity: {
            is_new_user,
            has_contact_looksee,
            is_left_promo_account,
          }
        } = data;

        // NOT meet the 2 requirements (registered contact and sent at least one Looksee)
        if(!has_contact_looksee && is_new_user) {
          this.setState({
            loading: false,
            unregistered_user: false,
            registered_looksee_user: true
          });
        } else {
          this.setState({
            loading: false,
            unregistered_user: true,
            registered_looksee_user: false
          });
        }

        setTimeout(function(){ 
          window.scrollTo({ 
            top: document.documentElement.scrollHeight, 
            behavior: 'auto'
            /* you can also use 'auto' behaviour 
               in place of 'smooth' */
          }); 
         }, 300);
      }
      
    }).catch(err=> {
      console.log(err);
    })
  }
  

  render() {
    const { iphone, ipad, computer, username, password, loading, user_device, is_new_user, registered_looksee_user, unregistered_user, promo_iphone,  promo_ipad,  promo_computer } = this.state;
    // console.log({iphone, ipad, computer, username, password, loading, user_device, is_new_user, unregistered_user, promo_iphone,  promo_ipad,  promo_computer})
    const iPhoneCond = (iphone === promo_iphone) ? true: false;
    const ipadCond = (ipad === promo_ipad) ? true: false;
    const computerCond = (computer === promo_computer) ? true: false;

    let old_user_message = (!is_new_user)? "You have already redeemed this offer! Here are the details:": "";
    let store = (user_device === 'pc')? "Windows": "App"
    let new_devices = (user_device === 'iphone')? 'iPhone': (user_device === 'ipad')? "iPad": (user_device === 'mac')? "MAC": "Windows 10 PC";
    let info = (user_device == 'pc' || user_device == 'mac')? "This account only works with installed applications (Word, Excel, PowerPoint)": "";

    return (
      <>
        {/* <DemoNavbar /> */}
        <main ref="main">
          <section className="section section-shaped section-lg ">
            <Container className="pt-lg-7">
              <Row className="justify-content-center">
                <Col lg="7">
                  <Card className="bg-secondary shadow border-0">                    
                    <CardBody className="px-lg-3 py-lg-3">
                      <div className="text-center text-muted mb-4">
                        <h3  style={{"color": "#495057"}}>Office 365 Giveaway!</h3>
                        <span className="error_message"></span>
                      </div>
                      <Form role="form" onSubmit={this.handleSubmit}>
                          <label className="" style={{"color": "#495057"}}>
                              I created my Looksee account with this mobile number:
                              </label>
                        <FormGroup>
                          <InputGroup className="input-group-alternative mb-3">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="ni ni-mobile-button" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input className="numberField" placeholder="Enter number with county code" type="number" style={{ "color": "#000", "fontSize":"20px"}} name="mobile_no" onChange={this.handleInputChange} required/>
                          </InputGroup>
                        </FormGroup>
                        <FormGroup style={{"paddingTop": "15px"}}>
                          <label htmlFor="exampleInputEmail1"  style={{"color": "#495057"}}>I want to redeem my Free Office 365 Account for: </label>
                          <Col sm={10}>
                            <div className="custom-control custom-radio mb-3">                            
                              <input
                                className="custom-control-input text-black"
                                id="customRadio5"
                                name="device"
                                type="radio"
                                value="iphone"
                                onChange={this.handleInputChange}
                                disabled={ iPhoneCond }
                                required
                              />
                              <label className="custom-control-label" htmlFor="customRadio5"  style={{"color": "#495057"}}>
                                iPhone
                              </label>
                            </div>
                            <div className="custom-control custom-radio mb-3">
                              <input
                                className="custom-control-input"
                                // defaultChecked
                                id="customRadio6"
                                name="device"
                                type="radio"
                                value="ipad"
                                onChange={this.handleInputChange}
                                disabled={ ipadCond }
                                required
                              />
                              <label className="custom-control-label" htmlFor="customRadio6"  style={{"color": "#495057"}}>
                                iPad
                              </label>
                            </div>
                            <div className="custom-control custom-radio mb-3">
                              <input
                                className="custom-control-input"
                                                            
                                id="customRadio8"
                                name="device"
                                type="radio"
                                value="mac"
                                onChange={this.handleInputChange}
                                disabled={ computerCond }
                                required
                              />
                              <label className="custom-control-label" htmlFor="customRadio8"  style={{"color": "#495057"}}>
                                MAC
                              </label>
                            </div>
                            <div className="custom-control custom-radio mb-3">
                              <input
                                className="custom-control-input"
                                
                                id="customRadio7"
                                name="device"
                                type="radio"
                                value="pc"
                                onChange={this.handleInputChange}
                                disabled={ computerCond }
                                required
                              />
                              <label className="custom-control-label" htmlFor="customRadio7"  style={{"color": "#495057"}}>
                                Windows 10 PC
                              </label>
                            </div>

                          </Col>
                        </FormGroup>
                          <div className="text-center">
                            <Button
                              className="mt-4"
                              color="primary"
                              type="submit"
                            >
                              Submit
                            </Button> &nbsp; &nbsp; &nbsp; <span className="loading"></span>
                          </div>
                      </Form>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </section>
          
          {(loading) && <section className="section section-lg pt-4 display_section" ref="messageList">
            <Container>
              <Card className="bg-gradient-white shadow-lg border-0">
                <div className="p-4">
                  <Row className="align-items-center">
                    <Col lg="12">
                      <h4 className="" style={{"color": "#495057"}}>{old_user_message}</h4>
                      <h4 className="" style={{"color": "#495057"}}>
                        How to Install Office 365 on your {new_devices}:
                        
                      </h4>
                      <p className=" mt-2" style={{"color": "#495057"}}>
                        1. Go to {store} Store<br />
                        2. Download Microsoft Word, Excel & PowerPoint<br />
                        3. Sign in with your free account (email & password) given below:</p>
                      <p className="mt-1">
                        <span className="" style={{"color": "#495057"}}>Username:</span> <span className="" style={{"color": "#000"}}> <strong>{username}</strong></span>
                        <br/>
                        <span className="" style={{"color": "#495057"}}>Password:</span> <span className="" style={{"color": "#000"}}> <strong> {password} </strong> </span></p>
                      <p className=" mt-2" style={{"color": "#495057"}} >Please note that your promo license will work on ONE {new_devices} only. <br /> {info}</p>
                      <p className="mt-2" style={{"color": "#495057"}} >If there is an issue, please email us; support@reallifeapp.com</p>
                      <p className="mt-2" style={{"color": "#495057"}} >
                        Thank you for participating! <br />
                        Real Life Apps
                      </p>
                    </Col>
                  </Row>
                </div>
              </Card>
            </Container>
            </section> }

            {(unregistered_user) && <section className="section section-lg pt-4 display_section" ref="messageList">
            <Container>
              <Card className="bg-gradient-white shadow-lg border-0">
                <div className="p-4">
                  <Row className="align-items-center">
                    <Col lg="12">
                      <h4 className="text-black" style={{"color": "#495057"}}>Sorry, we did not find a Looksee account associated with your mobile number! </h4>
                      <p className="mt-2" style={{"color": "#495057"}}>                        
                        1. Please download Looksee from the Apple App Store (available on iOS only) <br/>
                        2. Create a Looksee account<br/>
                        3. Invite a few friends to do the same<br/>
                        4. Send a few Looksees to your friends<br/>
                        5. Refresh this page and enter your mobile number. Please enter country code and mobile number<br/>
                        6. For example, US number is entered like: 15125951234
                      </p>
                    </Col>
                  </Row>
                </div>
              </Card>
            </Container>
            </section> }

            {(registered_looksee_user) && <section className="section section-lg pt-4 display_section" ref="messageList">
            <Container>
              <Card className="bg-gradient-white shadow-lg border-0">
                <div className="p-4">
                  <Row className="align-items-center">
                    <Col lg="12">
                      <h4 className="text-black" style={{"color": "#495057"}}>How to Qualify for our Promotional Giveaway! </h4>
                      <p className="mt-2" style={{"color": "#495057"}}>                        
                      1. Looksee is a Social Productivity App; it works best when your inner social circle is also on Looksee! <br />
                      2. Invite your loved ones to download Looksee and register for an account <br />
                      3. Send at least one Looksee to qualify for this offer <br />
                      4. Please note that the Looksee recipient must also be a registered Looksee user <br />
                      5. You can refer this giveaway to your friends and they can also get a free Office 365 account!   <br />
                      </p>
                    </Col>
                  </Row>
                </div>
              </Card>
            </Container>
            </section> }
        </main>
      </>
    );
  }
}

export default Register;
